<template>
    <div>
        <el-row style="padding:20px 0 10px">
            <el-col :span="6">分润金额：{{''|| 0.00}}元</el-col>
        </el-row>
        <el-table stripe border v-loading="loading" :data="dataList" >
            <el-table-column label="日期" prop="" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="分润类型" prop="" :show-overflow-tooltip="true"/>
            <el-table-column label="业务类型" prop=""  :show-overflow-tooltip="true"/>
            <el-table-column label="代理商编号" prop="" :show-overflow-tooltip="true"/>
            <el-table-column label="代理商名称" prop="" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="分润金额(元)" prop="" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="产生时间" prop="" min-width="" :show-overflow-tooltip="true"/>
            <el-table-column label="备注" prop="" min-width="" :show-overflow-tooltip="true"/>
        </el-table>
        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
    </div>
</template>

<script>
    import {TradeApi} from '@/api';
    import { mapState } from 'vuex'
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        computed:{
            ...mapState('app', ['userInfo']),
            isOrg(){
                return this.userInfo.org
            },
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false,
                allCount:''
            }
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await TradeApi.queryPage(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data.orderPageList || [];
                this.allCount = result.data.orderCountInfo || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            async handleUpdate(row) {
                this.showCustomForm = true;
                this.selectRow = row;
                this.isAdd = false;
            },
        }
    };
</script>

<style scoped>

</style>
